import React from 'react';

const Footer = () => (
	<footer>
		<p>
			{/* Proudly published with <a href="https://prismic.io" target="_blank" rel="noopener noreferrer">Prismic</a>
      <br />
      <a href="https://prismic.io" target="_blank" rel="noopener noreferrer">
        <img className="footer-logo" src={prismicLogo} alt="Prismic logo" />
      </a> */}
			If you like our app please leave us a good rating &#11088;&#11088;&#11088;&#11088; on playstore.
		</p>
	</footer>
);

export default Footer;
